import styled from 'styled-components';
import { media } from './theme';

export default styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 1s;

  .painting-title {
    font-weight: ${({ theme }) => theme.font.bold};
    text-align: center;
    padding: 4rem 3.5rem 0;

    h2& {
      font-size: 1.8rem;
    }
    h1& {
      font-size: 3rem;
    }
  }
  .painting-info {
    font-size: 1.4rem;
    font-family: ${({ theme }) => theme.font.mono};
    text-align: center;
    padding: 0 3.5rem 2rem;
  }

  ${media.break`
    max-height: 100%;
    height: 100%;
    @supports (display: grid) {
      display: grid;
      grid-template-rows: ${({ single }) =>
        single ? 'auto 1fr auto' : '1fr auto auto'};
      .image-wrapper {
        position: relative;
        .gatsby-image-wrapper{
          position: absolute !important;
          width: 100%;
          height: 100%;
        }
      }
    }
  `}
`;
