import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const All = ({ link, style }) => {
  return (
    <AllButton className="all-button" to={link} style={style}>
      All
      <div>
        <span />
        <span />
      </div>
    </AllButton>
  );
};

const AllButton = styled(Link)`
  background: ${({ theme }) => theme.black};
  color: white;
  border: 0;
  font-family: ${({ theme }) => theme.font.mono};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  margin: 2rem auto 0;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  transition: 0.25s;

  div {
    height: 22px;
    width: 22px;
    margin-left: 0.5rem;
    border: 1px solid white;
    position: relative;
    span {
      position: absolute;
      background: white;
      :nth-child(1) {
        width: 100%;
        height: 1px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
      :nth-child(2) {
        height: 100%;
        width: 1px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }
    }
  }
`;
export default All;
