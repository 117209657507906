import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import left from '../images/Arrow-left.png';
import right from '../images/Arrow-right.png';

const Next = ({ onClick, style, link }) => {
  return link ? (
    <StyledLink
      className="prev-next-button next"
      direction="next"
      to={link}
      style={style}
    >
      Next
    </StyledLink>
  ) : (
    <StyledButton
      className="prev-next-button next"
      direction="next"
      onClick={onClick}
      style={style}
    >
      Next
    </StyledButton>
  );
};

const Prev = ({ onClick, style, link }) => {
  return link ? (
    <StyledLink
      className="prev-next-button prev"
      direction="prev"
      to={link}
      style={style}
    >
      Prev
    </StyledLink>
  ) : (
    <StyledButton
      className="prev-next-button prev"
      direction="prev"
      onClick={onClick}
      style={style}
    >
      Prev
    </StyledButton>
  );
};

const SliderPrev = ({ onClick, show }) => {
  if (!show) {
    return null;
  }
  return (
    <SliderButton
      className="slider-button slider-prev"
      onClick={onClick}
      direction="prev"
    >
      <img src={left} alt="Left" />
    </SliderButton>
  );
};

const SliderNext = ({ onClick, show }) => {
  if (!show) {
    return null;
  }
  return (
    <SliderButton
      className="slider-button slider-next"
      onClick={onClick}
      direction="next"
    >
      <img src={right} alt="Right" />
    </SliderButton>
  );
};

const SliderButton = styled.button`
  background: ${({ theme }) => theme.black};
  border: 0;
  z-index: 2;
  position: absolute;
  width: 70px;
  height: 70px;
  padding: 0;
`;

const ButtonStyles = css`
  color: white;
  border-width: 0;
  font-family: ${({ theme }) => theme.font.mono};
  font-size: 1.4rem;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  height: 18px;
  background: transparent;

  width: 50px;
  ::before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.black};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    height: 100%;

    border-right-width: ${({ direction }) =>
      direction === 'prev' ? '5px' : '0'};
    border-left-width: ${({ direction }) =>
      direction === 'prev' ? '0' : '5px'};
    border-right-color: ${({ theme }) => theme.black};
    border-left-color: ${({ theme }) => theme.black};
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-top-width: 9px;
    border-bottom-width: 9px;
    ${({ direction }) =>
      direction === 'prev'
        ? `
      right: 100%;
    `
        : `
      left: 100%;
    `}
    top: 0;
    width: 5px;
    z-index: 2;
  }
`;

const StyledButton = styled.button`
  ${ButtonStyles}
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}
  display: block;
  text-decoration: none;
  text-align: center;
`;

export { Next, Prev, SliderPrev, SliderNext };
